@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
    .form-control {
        @apply flex flex-col gap-2;
    }
    .form-control label {
        @apply text-xl font-bold text-vidalink-blue;
    }
    input,
    select,
    textarea,
    .data-picker {
        @apply rounded-xl border border-vidalink-blue py-3 px-4 text-xl outline-none focus:ring-2 focus:ring-vidalink-blue disabled:bg-vidalink-gray-100;
    }

    #prescription-table table {
        @apply mt-10;
    }

    select {
        @apply appearance-none bg-no-repeat pr-12;
        background-position: right 0.7em top 50%, 0 0;
        background-size: 1.875rem auto, 100%;
        background-repeat: no-repeat, repeat;
        background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjkiIGhlaWdodD0iMjkiIHZpZXdCb3g9IjAgMCAyOSAyOSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGNpcmNsZSBjeD0iMTQuNSIgY3k9IjE0LjUiIHI9IjE0IiBmaWxsPSJ3aGl0ZSIgc3Ryb2tlPSIjMzc3Q0I5Ii8+CjxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNMTQuODYwNCAxOS44MzIxQzE0LjY0MjEgMjAuMDU2IDE0LjM1NzkgMjAuMDU2IDE0LjEzOTYgMTkuODMyMUw4LjI4OTUxIDEzLjgzMjJDNy45OTA4NyAxMy41MjU5IDcuOTEwMTIgMTIuOTA1MSA4LjEwOTE1IDEyLjQ0NTVDOC4zMDgxOCAxMS45ODYgOC43MTE2MiAxMS44NjE3IDkuMDEwMjYgMTIuMTY4TDE0LjUgMTcuNzk4M0wxOS45ODk3IDEyLjE2OEMyMC4yODg0IDExLjg2MTcgMjAuNjkxOCAxMS45ODYgMjAuODkwOCAxMi40NDU1QzIxLjA4OTkgMTIuOTA1MSAyMS4wMDkxIDEzLjUyNTkgMjAuNzEwNSAxMy44MzIyTDE0Ljg2MDQgMTkuODMyMVoiIGZpbGw9IiMzNzdDQjkiLz4KPC9zdmc+Cg==");
    }
}

@layer utilities {
    .MuiToolbar-root button {
        @apply mx-2;
    }

    [class*="MUIDataTableFilter-root"] {
        @apply rounded-xl p-9 pb-14 shadow-vidalink !important;
    }
    [class*="MUIDataTableFilter-header"] {
        @apply hidden !important;
    }
    [class*="MuiGrid-item"] {
        @apply w-11/12 p-0 px-2  !important;
    }
    [class*="MuiGrid-item"] [class*="MuiFormControl-root"] label {
        @apply mb-20 block font-sans text-xl font-bold text-vidalink-blue !important;
    }

    [class*="MuiGrid-item"] [class*="MuiFormControl-root"] label + div:hover {
        @apply shadow-none;
    }

    [class*="MuiGrid-item"] [class*="MuiFormControl-root"] label + div {
        @apply mt-8 h-[53px] p-0 font-sans text-vidalink-gray-700 !important;

        @apply rounded-xl border border-vidalink-blue py-3 px-4 text-xl outline-none focus:ring-2 focus:ring-vidalink-blue disabled:bg-vidalink-gray-100 !important;
        @apply appearance-none bg-no-repeat !important;
        background-position: right 0.7em top 50%, 0 0;
        background-size: 1.875rem auto, 100%;
        background-repeat: no-repeat, repeat;
        background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjkiIGhlaWdodD0iMjkiIHZpZXdCb3g9IjAgMCAyOSAyOSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGNpcmNsZSBjeD0iMTQuNSIgY3k9IjE0LjUiIHI9IjE0IiBmaWxsPSJ3aGl0ZSIgc3Ryb2tlPSIjMzc3Q0I5Ii8+CjxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNMTQuODYwNCAxOS44MzIxQzE0LjY0MjEgMjAuMDU2IDE0LjM1NzkgMjAuMDU2IDE0LjEzOTYgMTkuODMyMUw4LjI4OTUxIDEzLjgzMjJDNy45OTA4NyAxMy41MjU5IDcuOTEwMTIgMTIuOTA1MSA4LjEwOTE1IDEyLjQ0NTVDOC4zMDgxOCAxMS45ODYgOC43MTE2MiAxMS44NjE3IDkuMDEwMjYgMTIuMTY4TDE0LjUgMTcuNzk4M0wxOS45ODk3IDEyLjE2OEMyMC4yODg0IDExLjg2MTcgMjAuNjkxOCAxMS45ODYgMjAuODkwOCAxMi40NDU1QzIxLjA4OTkgMTIuOTA1MSAyMS4wMDkxIDEzLjUyNTkgMjAuNzEwNSAxMy44MzIyTDE0Ljg2MDQgMTkuODMyMVoiIGZpbGw9IiMzNzdDQjkiLz4KPC9zdmc+Cg==");
    }

    [class*="MuiInput-underline"]:before,
    [class*="MuiInput-underline"]:after {
        @apply hidden !important;
    }

    [class*="MuiGrid-item"] [class*="MuiFormControl-root"] label + div svg {
        @apply hidden !important;
    }

    [class*="MUIDataTableBodyCell-root"] {
        text-align: center;
    }
}

html {
    @apply min-w-[48rem];
}

[class*="MuiTableBody-root"] tr:nth-child(2n) {
    @apply rounded-xl bg-vidalink-gray-100;
}

[class*="MuiTableBody-root"] tr td {
    @apply border-0;
}

[class*="MuiTableBody-root"] tr td div {
    @apply text-center font-sans;
}

[class*="MuiTableBody-root"] tr td:first-child {
    @apply rounded-l-2xl border-0;
}

[class*="MuiTableBody-root"] tr td:last-child {
    @apply rounded-r-2xl border-0 font-mono;
}

[class*="react-date-picker__wrapper"] {
    border: 0 !important;
}

.react-date-picker__inputGroup input {
    background: none;
}

@media only screen and (max-width: 1440px) {
    html {
        zoom: 85%;
    }
}

.react-transform-wrapper,
.react-transform-component {
    @apply h-full w-full !important;
}

.react-date-picker {
    display: inline-flex;
    position: relative;
}

.react-date-picker,
.react-date-picker *,
.react-date-picker *:before,
.react-date-picker *:after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

.react-date-picker--disabled {
    background-color: #f0f0f0;
    color: #6d6d6d;
}

.react-date-picker__wrapper {
    display: flex;
    flex-grow: 1;
    flex-shrink: 0;
    border: thin solid gray;
}

.react-date-picker__inputGroup {
    min-width: calc((4px * 3) + 0.54em * 8 + 0.217em * 2);
    flex-grow: 1;
    padding: 0 2px;
    box-sizing: content-box;
}

.react-date-picker__inputGroup__divider {
    padding: 1px 0;
    white-space: pre;
}

.react-date-picker__inputGroup__divider,
.react-date-picker__inputGroup__leadingZero {
    display: inline-block;
}

.react-date-picker__inputGroup__input {
    min-width: 0.54em;
    height: 100%;
    position: relative;
    padding: 0 1px;
    border: 0;
    background: none;
    font: inherit;
    box-sizing: content-box;
    -webkit-appearance: textfield;
    -moz-appearance: textfield;
    appearance: textfield;
}

.react-date-picker__inputGroup__input::-webkit-outer-spin-button,
.react-date-picker__inputGroup__input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
}

.react-date-picker__inputGroup__input:invalid {
    background: rgba(255, 0, 0, 0.1);
}

.react-date-picker__inputGroup__input--hasLeadingZero {
    margin-left: -0.54em;
    padding-left: calc(1px + 0.54em);
}

.react-date-picker__button {
    border: 0;
    background: transparent;
    padding: 4px 6px;
}

.react-date-picker__button:enabled {
    cursor: pointer;
}

.react-date-picker__button:enabled:hover .react-date-picker__button__icon,
.react-date-picker__button:enabled:focus .react-date-picker__button__icon {
    stroke: #0078d7;
}

.react-date-picker__button:disabled .react-date-picker__button__icon {
    stroke: #6d6d6d;
}

.react-date-picker__button svg {
    display: inherit;
}

.react-date-picker__calendar {
    width: 350px;
    max-width: 100vw;
    z-index: 1;
}

.react-date-picker__calendar--closed {
    display: none;
}

.react-date-picker__calendar .react-calendar {
    border-width: thin;
}
